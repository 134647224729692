'use strict'

import * as $ from 'jquery';

export default function (opts) {

    // Create and configure options as necessary
    const options = opts || {};
    options.position = options.position || "left";


    /* --- Tree walking --------------------------------------------- */

    function visitAncestors(page, action) {
        var parent = page.parent;
        while (parent) {
            action(parent);
            parent = parent.parent;
        }
    }

    function visitDescendants(page, action) {
        var children = [].concat(page.children);
        while (children.length > 0) {
            var child = children.shift();
            action(child);
            children = children.concat(child.children);
        }
    }

    function visitAll(page, action) {
        action(page);
        visitAncestors(page, action);
        visitDescendants(page, action);
    }

    /* --- Menu state manipulation --------------------------------- */

    function resetPage(page) {
        if (page.fragment) {
            page.fragment.removeClass("above focussed below");
        }
    }

    function resetAll(page) {
        visitAll(page, function (p) {
            resetPage(p);
        });
    }

    function focusPage(page) {
        resetAll(page);
        page.fragment.addClass("focussed");
        visitAncestors(page, function (p) {
            p.fragment.addClass("above");
        });
        visitDescendants(page, function (p) {
            p.fragment && p.fragment.addClass("below");
        });
    }

    /* --- Handlers ------------------------------------------------ */

    function showMenu(menu) {
        $("body").removeClass("animate");
        focusPage(menu.showing || menu);
        window.setTimeout(function () {
            $("body").addClass("animate drawer_opened");
            var width = $("#drawer_dummy").css("width");
            let dir = options.position === "right" ? "-" : "+";
            $("#page_wrapper").css("transform", `translateX(${dir}${width})`);
            $("#page_wrapper").css("overflow-x", "hidden");
        }, 10);
    }

    function hideMenu(menu) {
        $("body").removeClass("animate drawer_opened");
        $("#page_wrapper").css("transform", "none");
        $("#page_wrapper").css("overflow-x", "");
    }


    /* --- DOM manipulation ---------------------------------------- */

    function makeMenuDrawer() {
        var $body = $("body");

        // Remove original content
        var $content = $body.children().not("script, #tracking_dialog").detach();
        // Prevent double-execution of scripts
        $content.find("script").remove();

        // Will contain the original page content
        var $wrapper = $("<div>").attr({
            id: "page_wrapper"
        }).css("background", $body.css("background"));
        $body.append($wrapper);

        // Move all top-level content to new container
        $wrapper.append($content);

        // For measuring only
        var $dummy = $("<div>").attr({
            id: "drawer_dummy"
        }).addClass("drawer");
        $body.append($dummy);

        // Will contain the drawer menu
        var $menu = $("<div>").attr({
            id: "drawer_menu"
        }).addClass(`${options.position} drawer`);
        $body.append($menu);

        return $menu
    }

    function makePageFragment(page) {
        page.fragment = $("<ul>").addClass("menu_page");

        var $header = $("<li>").addClass("container").append($("<a>").addClass("close").html("&#x2715;").click(function (e) {
            hideMenu();
            resetAll(page);
        }));
        if (page.parent) {
            $header.append($("<a>").addClass("back").html("&#x25c0;").click(function (e) {
                focusPage(page.parent);
            }));
        }
        var $a = $("<a>").addClass("current").html(page.text);
        if (page.link) {
            $a.attr("href", page.link.attr("href"));
        }
        $header.append($a);
        page.fragment.append($header);

        $.each(page.children, function (i, child) {
            var $child = $("<li>");
            if (child.children.length > 0) {
                $child.append($("<a>").addClass("more").html("&#x25b6;").click(function (e) {
                    focusPage(child);
                }));
            }
            if (child.active) {
                $child.addClass("active");
            }
            $child.append(child.link.addClass("child"));
            page.fragment.append($child);
        });
    }

    /* --- Initialisation ------------------------------------------ */

    /*
     * $list: the <ul> containing menu items
     * $menu: the mobile menu container (or null)
     * page: the object representing this $menu's page (or null)
     */
    function buildMenu($list, $menu, page) {

        // Create menu drawer, if necessary
        $menu = $menu || makeMenuDrawer();

        // Create page, if necessary
        page = page || {
            parent: null,
            children: [],
            text: "",
            link: null,
            active: false,
            showing: null,
            fragment: null
        };

        $.each($list.children("li"), function (i, e) {
            var $item = $(e);
            var subPage = {
                parent: page,
                children: [],
                text: $item.children("a").text(),
                link: $item.children("a"),
                active: $item.hasClass("current")
            }

            if (subPage.active) {
                page.showing = page;
            }

            var $subList = $item.children("ul");
            if ($subList.length > 0) {
                buildMenu($subList, $menu, subPage);
            }

            if (subPage.showing) {
                page.showing = subPage.showing;
            }

            page.children.push(subPage);
        });

        makePageFragment(page);
        $menu.append(page.fragment);

        return page;
    }



    /* --- Startup code ------------------------------------------- */

    // Mobile menu definition becomes menu trigger
    const $trigger = $("#hamburger").append("<div>", "<div>", "<div>");

    // Get possible menu underneath trigger
    let $items = $trigger.children("ul").remove();
    // If some other menu has been specified...
    if (options.menu) {
        // ...get a copy thereof
        $items = $(options.menu).first("ul").contents().addBack("ul").clone();
    }

    // Create menu drawer

    // Build menu pages
    const menu = buildMenu($items);


    /* --- Wiring --- */

    // Open drawer menu on trigger click
    $trigger.click(function (e) {
        showMenu(menu);
    });

    // Close menu on page overlay click
    $("#overlay").click(function (e) {
        hideMenu(menu);
    });

};
