import * as $ from 'jquery';
//import 'lightbox2';
import mobile_menu from './mobile_menu.js';
//import mehr_toggle_button_hider from './mehr_toggle_button_hider';
//import touch_tooltip from './touch_tooltip';

(() => {

        function update_geometry() {
                $("#overlay").width($(window).width());
                $("#overlay").height($(window).height());
        }

        $(document).ready(function () {
                update_geometry();
                mobile_menu({menu: $('#topnav'), position: 'right'});
                //mehr_toggle_button_hider();
                //touch_tooltip();
        });
        $(window).resize(function () {
                update_geometry();
                //mehr_toggle_button_hider();
        });

})();